<template>
    <div class="shop_main">
        <div class="shop_search_menu">
            <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <span class="on">商务合作</span>
        </div>

        <div class="shop_help">

            <div class="shop_form mt30" style="width: 580px; margin-left: auto; margin-right: auto">
                <el-form ref="form" :model="form" :rules="rules" label-width="108px" size="small">
                    <el-form-item label="公司名称：" class="mb20" prop="companyName">
                        <el-input v-model="form.companyName" placeholder="请输入公司名称" class="fullInput"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人：" class="mb12" prop="userName">
                        <el-input v-model="form.userName" placeholder="请输入联系人姓名" class="fullInput"></el-input>
                    </el-form-item>
                    <el-form-item class="mb20">
                        <el-radio-group v-model="form.userSex">
                            <el-radio :label="1">先生</el-radio>
                            <el-radio :label="2">女士</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="联系电话：" class="mb20" prop="phone">
                        <el-input v-model="form.phone" placeholder="请输入联系电话，以便我们和您取得联系！" class="fullInput"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：" class="mb20" prop="email">
                        <el-input v-model="form.email" placeholder="请输入您的邮箱号码，方便我们在线与您取得联系！" class="fullInput"></el-input>
                    </el-form-item>
                    <el-form-item label="洽谈内容描述：" class="mb20" prop="dese">
                        <el-input v-model="form.dese" placeholder="请输入合作洽谈相关内容" type="textarea" :rows="4" class="fulltextarea"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="员工人数：" class="mb20">-->
<!--                        <el-input v-model.number="form.staffNum" placeholder="请输入员工人数" class="fullInput"></el-input>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="您的职务：" class="mb20">-->
<!--                        <el-input v-model="form.position" placeholder="请输入您的职务" class="fullInput"></el-input>-->
<!--                    </el-form-item>-->

                    <el-form-item style="text-align: center; padding-right: 104px">
                        <el-button type="primary" :loading="submitLoading" @click="submitForm('form')">提交申请</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>
    </div>
</template>

<script>
  import {postCooperate} from '../../api/shop/article'
  export default {
    name: "shopCooperate",
    data () {
      return {
        form: {
          resource: 0,
          userSex: 1,
          userName: '',
          companyName: '',
          phone: '',
          email: '',
          dese: '',
        },
        rules: {
          userName: [
            { required: true, message: '请输入联系人姓名', trigger: 'blur' }
          ],
          // staffNum: [
          //   { required: true, message: '请输入员工人数', trigger: 'blur' }
          // ],
          companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          // position: [
          //   { required: true, message: '请输入您的职务', trigger: 'blur' }
          // ],
          phone: [
            { required: true, message: '请输入联系电话', trigger: 'blur' },
            { pattern: '^([1]\\d{10}|([\\(（]?0[0-9]{2,3}[）\\)]?[-]?)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?)$', message: '请输入正确的联系电话', trigger: 'blur'}
          ],
          // email: [
          //   { required: true, message: '请输入您的邮箱号码', trigger: 'blur' },
          //   { type: 'email', message: '请输入正确的邮箱号码', trigger: 'blur' }
          // ],
          dese: [
            { required: true, message: '请输入合作洽谈相关内容', trigger: 'blur' }
          ]
        },
        submitLoading: false
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
    },
    methods: {
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            postCooperate({
              data: this.form
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '您的资料已提交，我们会在1-3个工作日内联系您！',
                  showClose: true,
                  type: 'success',
                  customClass: 'messageCooperate'
                })
                this.$refs['form'].resetFields()
              }
            }).catch(() => {
              this.submitLoading = false
            })
          } else {
            return false;
          }
        });
      }
    },
    components: {}
  }
</script>
